import React, { useState } from "react";
import { Button, ElementContainer, TextBox } from "../../../core/elements";
import styled from "styled-components";
import { getData } from "../../../../backend/api"; // Assuming you have a getData function for fetching data
import withLayout from "../../../public/layout";

const TextDiv = styled.div`
  display: flex;
  gap: 10px;
  width: 50%;
`;
const ButtonDiv = styled.div`
  margin-top: 23px;
`;

const Results = (props) => {
  console.log(props);

  const [regNo, setRegNo] = useState("");
  const [name, setName] = useState("");
  // const [noData, setNoData] = useState(null);
  const [exam, setExam] = useState("");
  const [result, setResult] = useState([]);
  // const [score, setScore] = useState("");
  const [published, setPublished] = useState(false);

  // Calculate the grade based on the score
  const calculateGrade = (score) => {
    if (score >= 91 && score <= 100) {
      return "A+";
    } else if (score >= 81 && score <= 90) {
      return "A";
    } else if (score >= 71 && score <= 80) {
      return "B+";
    } else if (score >= 61 && score <= 70) {
      return "B";
    } else if (score >= 51 && score <= 60) {
      return "C+";
    } else if (score >= 41 && score <= 50) {
      return "C";
    } else if (score >= 1 && score <= 40) {
      return "D+";
    } else {
      return "Grade Not Published"; // Handle invalid scores
    }
  };
  return (
    <ElementContainer
      className="dashboard"
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "0px",
        flexWrap: "nowrap",
      }}
    >
      <div style={{ marginLeft: "30px", marginTop: "50px" }}>
        <h2 style={{ marginTop: "0px", marginBottom: "10px" }}>Exam Result</h2>
        <h4 style={{ marginTop: "10px", color: "Red" }}>
          ഫൈനല്‍ പരീക്ഷകളായ പ്രിലിമിനറി അഞ്ചാം വർഷം, സെക്കൻഡറി നാലാം വർഷം
          എഴുതിയവരുടെ റിസൾട്ട് മാത്രമാണ് ഇപ്പൊൾ പബ്ലിഷ് ചെയ്തിട്ടുള്ളത്.
          മറ്റുള്ളവ പിന്നീട് പബ്ലിഷ് ചെയ്യുന്നതാണ്.{" "}
        </h4>
        <TextDiv>
          <TextBox
            className="text-box"
            label="Enter Register Number / Mobile Number"
            value={regNo}
            onChange={(value) => {
              console.log("Text Changed", value);
              setRegNo(value);
            }}
          ></TextBox>
          <ButtonDiv>
            <Button
              className="btn-search"
              type={"secondary"}
              align="right"
              icon={"search"}
              ClickEvent={() => {
                getData({ regno: regNo }, "exam-registration/result").then(
                  (response) => {
                    if (response.status === 200) {
                      const responseData = response.data.response[0];
                      setName(responseData.nameOfApplicant);
                      setExam(responseData.nameOfExamAppearingNow.examType);

                      const resultData = response?.data?.result;
                      setResult(resultData);

                      // Directly check response.data.result for setting 'published'
                      if (resultData && resultData.score && resultData.grade) {
                        setPublished(true);
                      } else {
                        setPublished(false); // In case result is incomplete
                      }
                    } else if (response.success === false) {
                      props.setMessage({
                        type: 1,
                        content: response?.customMessage,
                        proceed: "Okay",
                      });
                    } else {
                      props.setMessage({
                        type: 1,
                        content: "Result Not Published",
                        proceed: "Okay",
                      });
                      console.warn("No exam data found");

                      // Clear any previous data if no result is found
                      setName("");
                      setExam("");
                      setResult([]);
                      // setNoData("Data Not Found");
                    }
                  }
                );
              }}
              value="Search"
            ></Button>
          </ButtonDiv>
        </TextDiv>
        {published &&
          (result?.score && result?.grade ? (
            <h2 style={{ marginTop: "10px", color: "green" }}>
              Result Published
            </h2>
          ) : (
            <h2 style={{ marginTop: "10px", color: "Red" }}>
              Result Not Published
            </h2>
          ))}

        {result?.length != 0 && (
          <>
            <p
              style={{
                textAlign: "left",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <b>Name :</b> {name}
            </p>
            <p
              style={{
                textAlign: "left",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <b>Exam :</b> {exam}
            </p>
            <p
              style={{
                textAlign: "left",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <b>Grade :</b> {calculateGrade(result?.score)}
              {/* {result?.grade ? result.grade : "Result Not Published"} */}
            </p>
          </>
        )}
      </div>
    </ElementContainer>
  );
};

export default withLayout(Results);
